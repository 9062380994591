import React, { Fragment, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { config as i18nextConfig } from '../../translations';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import AuthContext from '../../authentification/AuthContext';
import useLocalStorage from '../../authentification/AuthContext/useLocalStorage';
import BusinessContext from '../../BusinessContext';
import LanguageContext from '../../LanguageContext';

i18n.init(i18nextConfig);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
      setTimeout(() => setIsLoaded(true), 500);
    });
  }, []);

  const initialUser = {};
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', initialUser);
  const value = { currentUser, setCurrentUser };

  const initialBusiness = '';
  const [currentBusiness, setCurrentBusiness] = useLocalStorage('currentBusiness', initialBusiness);
  const business = { currentBusiness, setCurrentBusiness };

  const initialLanguage = '';
  const [selectedLanguage, setLanguage] = useLocalStorage('selectedLanguage', initialLanguage);
  const language = { selectedLanguage, setLanguage };

  return (
    <Provider store={store}>
      <AuthContext.Provider value={value}>
        <BusinessContext.Provider value={business}>
          <LanguageContext.Provider value={language}>
            <BrowserRouter>
              <I18nextProvider i18n={i18n}>
                <ScrollToTop>
                  <Fragment>
                    {!isLoaded && (
                      <div className={`load${isLoading ? '' : ' loaded'}`}>
                        <div className="load__icon-wrap">
                          <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                          </svg>
                        </div>
                      </div>
                    )}
                    <div>
                      <Router />
                    </div>
                  </Fragment>
                </ScrollToTop>
              </I18nextProvider>
            </BrowserRouter>
          </LanguageContext.Provider>
        </BusinessContext.Provider>
      </AuthContext.Provider>
    </Provider>
  );
};

export default App;
