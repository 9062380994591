import React, { useContext, ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../authentification/AuthContext';

  interface PrivateRouteProps {
  component: ComponentType;
  path?: string,
  exact?: boolean,
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { currentUser } = useContext(AuthContext);

  const { token }: {token: string} = currentUser;

  return (
    <Route
      {...rest}
      render={(props: any) => (token ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
    />
  );
};

export default PrivateRoute;
