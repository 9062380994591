import React, { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import EyeIcon from 'mdi-react/EyeIcon';
import { useForm } from 'react-hook-form';
import { emailPatter } from '../../../shared/helpers';
import { FormField } from './FormField';
/* import showResults from '../../../Show'; */

export const Form = ({ isHorizontal, isAboveError, handleCreateAccount }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const showPassword = () => {
    setIsPasswordShown((prevState) => !prevState);
  };

  /* const onSubmit = data => showResults(data); */

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <form className={`form ${isHorizontal && 'form--horizontal'}`} onSubmit={handleSubmit(handleCreateAccount)}>
            <div className="form__form-group">
              <span className="form__form-group-label">First name</span>
              <div className="form__form-group-field">
                <FormField
                  name="firstName"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="First name"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Last name</span>
              <div className="form__form-group-field">
                <FormField
                  name="lastName"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Last name"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Username</span>
              <div className="form__form-group-field">
                <FormField
                  name="username"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Username"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">E-mail</span>
              <div className="form__form-group-field">
                <FormField
                  name="email"
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{
                    required: 'This is required field',
                    pattern: {
                      value: emailPatter,
                      message: 'Entered value does not match email format',
                    },
                  }}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="example@gmail.com"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <FormField
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                  placeholder="Password"
                  isAboveError={isAboveError}
                />
                <button
                  type="button"
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  tabIndex="-1"
                  onClick={showPassword}
                ><EyeIcon />
                </button>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Repeat password</span>
              <div className="form__form-group-field">
                <FormField
                  name="password"
                  type={isPasswordShown ? 'text' : 'password'}
                  control={control}
                  component="input"
                  errors={errors}
                  rules={{ required: 'This is required field' }}
                  defaultValue=""
                  placeholder="Please repeat Your password"
                  isAboveError={isAboveError}
                />
                <button
                  type="button"
                  className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
                  tabIndex="-1"
                  onClick={showPassword}
                ><EyeIcon />
                </button>
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">Description</span>
              <div className="form__form-group-field">
                <FormField
                  name="description"
                  control={control}
                  component="textarea"
                  errors={errors}
                  defaultValue=""
                  isAboveError={isAboveError}
                  placeholder="Please write a few words about yourself"
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Validate</Button>
              <Button
                type="button"
                onClick={() => reset({
                  username: '',
                  email: '',
                  url: '',
                  password: '',
                  select: '',
                })}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

Form.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

Form.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default Form;
