import React, { useEffect, useState, useContext } from 'react';

import { API } from '../../services/api';
import { paths } from '../../services/paths';
import { AuthContext } from '../../authentification/AuthContext';
import { MyBusinessPage } from '../../pages/MyBusinessPage';

export const FetchUserData: any = () => {
  const { currentUser } = useContext(AuthContext);
  const { token }: { token: string} = currentUser;
  const { businessList } : { businessList: string} = paths;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [userBusinessData, setUserBusinessData] = useState<any>();

  const path: string = businessList;

  const getUserData = async (url: string) => {
    try {
      const response = await API.get(url, config);
      const result = await response.json();
      setUserBusinessData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!userBusinessData) {
      return getUserData(path);
    } return userBusinessData;
  });

  return Boolean(userBusinessData) && <MyBusinessPage userBusinessData={userBusinessData} />;
};

export default FetchUserData;
