import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { BusinessList } from './components/BusinessList';

export const MyBusinessPage: any = ({ userBusinessData }: any) => {
  const { t } = useTranslation('common');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('myBusinessPage.headers.page-title')}</h3>
        </Col>
      </Row>
      <Row>
        <BusinessList userBusinessData={userBusinessData} />
      </Row>
    </Container>
  );
};

export default MyBusinessPage;
