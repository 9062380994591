import React, { useContext } from 'react';
import { Card, CardBody, Col } from 'reactstrap';

import { AuthContext } from '../../../authentification/AuthContext';

export const UserDataBlock: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    first_name: firstName,
    last_name: lastName,
    description,
  } : {
    first_name: string,
    last_name: string,
    description: string
  } = currentUser.person;
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">User Info</h5>
            <h5 className="subhead">Example subhead</h5>
          </div>
          <p>First name: {firstName}</p>
          <p>Last name: {lastName}</p>
          <p>Description: {description}</p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserDataBlock;
