export const BASE_URL = 'https://api.test.pinsteps.com';

export const defaultConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const API = {
  request(url: string, config = {}) {
    return fetch(url, {
      ...defaultConfig,
      ...config,
    });
  },
  get(path: string, config = {}) {
    return this.request(`${BASE_URL}${path}`, config);
  },
  post(path: string, config = { method: 'POST' }) {
    return this.request(`${BASE_URL}${path}`, config);
  },
};

export default API;
