import { createContext } from 'react';

const language: any = {
  selectedLanguage: '',
  setLanguage: () => {},
};

export const LanguageContext = createContext(language);

export default LanguageContext;
