/* eslint-disable camelcase */
import common_ru from './ru/common.json';
import common_en from './en/common.json';
import common_heb from './heb/common.json';

import errors_ru from './ru/errors.json';
import errors_en from './en/errors.json';
import errors_heb from './heb/errors.json';

export default {
  en: {
    common: common_en,
    errors: errors_en,
  },
  ru: {
    common: common_ru,
    errors: errors_ru,
  },
  heb: {
    common: common_heb,
    errors: errors_heb,
  },
};
