import React, { useState, useContext } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { AuthContext } from '../../../authentification/AuthContext';
import { BusinessContext } from '../../../BusinessContext';
import { LogOut } from '../../../authentification/LogOut';
import { createAuthorAvatar } from '../../../helpers';

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { currentUser } = useContext(AuthContext);
  const { full_name: fullName, facebook_user_id: facebookUserId, first_name: firstName } = currentUser.person;

  const { currentBusiness } = useContext(BusinessContext);

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={setIsCollapsed}>
        {
        facebookUserId
          ? (
            <img
              className="topbar__avatar-img"
              src={`https://graph.facebook.com/${facebookUserId}/picture`}
              alt="avatar"
            />
          )
          : <div className="topbar__avatar-img" style={{ color: '#ffffff' }}>{createAuthorAvatar(firstName)}</div>
        }
        <p className="topbar__avatar-name">{fullName} ({currentBusiness.title})</p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Account" icon="list" path="/pages/account" />
          <TopbarMenuLink title="My Business" icon="inbox" path="/pages/my-business" />
          <div className="topbar__menu-divider" />
          <LogOut />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;
