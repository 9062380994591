import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Form } from './components/CreateAccountForm';
import Alert from '../../shared/components/Alert';
import { API } from '../../services/api';
import { paths } from '../../services/paths';

/* eslint-disable consistent-return */

interface CreateAccountUserData {
    email: string,
    password: string,
    username: string,
    firstName: string,
    lastName: string,
    description: string,
}

export const CreateAccount: React.FC = () => {
  const [alertSettings, setAllertSettings] = useState({
    alertIsVisible: false,
    alertMessage: '',
    alertColor: '',
  });

  const { alertIsVisible, alertMessage, alertColor } = alertSettings;

  const path: string = paths.createAccount;

  const handleClose = () => {
    setAllertSettings({
      alertIsVisible: false,
      alertMessage: '',
      alertColor: '',
    });
  };

  const handleCreateAccount = async ({
    email,
    password,
    username,
    firstName,
    lastName,
    description,
  } : CreateAccountUserData) => {
    const config = {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        nickname: username,
        first_name: firstName,
        last_name: lastName,
        description,
      }),
    };

    try {
      const response = await API.post(path, config);
      const result = await response.json();
      console.log('result', result);

      if (response.status === 200) {
        setAllertSettings({
          alertIsVisible: true,
          alertMessage: result.message,
          alertColor: 'success',
        });
      } else {
        setAllertSettings({
          alertIsVisible: true,
          alertMessage: result.message,
          alertColor: 'danger',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
    }}
    >
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title text-center">Create account</h3>
              <p className="text-center">
                Please enter your details to create an account
              </p>
            </Col>
            {alertIsVisible && (
              <Row>
                <Col md={12}>
                  <Alert color={alertColor} className="alert--bordered" icon handleClose={handleClose}>
                    <p><span className="bold-text">{alertMessage}</span></p>
                  </Alert>
                </Col>
              </Row>
            )}
          </Row>
          <Row>
            <Form isHorizontal isAboveError handleCreateAccount={handleCreateAccount} />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CreateAccount;
