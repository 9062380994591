import { createContext } from 'react';

const user: any = {
  currentUser: {},
  setCurrentUser: () => {},
};

export const AuthContext = createContext(user);

export default AuthContext;
