import React from 'react';
import PropTypes from 'prop-types';
/* import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon'; */
import LogInForm from '../../authentification/LogIn/components/LogInForm';

export const LogIn = ({ handleLogin }) => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head" style={{ borderLeft: '4px solid #ea3f2b' }}>
          <h3 className="account__title">Welcome to
            <span
              className="account__logo"
              style={{
                color: '#ea3f2b',
                fontSize: '30px',
                fontFamily: 'Roboto ,Arial,sans-serif',
                lineHeight: '1',
                fontWeight: '300',
                letterSpacing: '3px',
                backgroundPosition: 'center center',
                borderColor: 'transparent',
                borderStyle: 'solid',
              }}
            >
              Pinsteps
              {/* <span className="account__logo-accent">DEV</span> */}
            </span>
          </h3>
          <h4 className="account__subhead subhead">Digital tours, guides and storytelling</h4>
        </div>
        <LogInForm handleLogin={handleLogin} />
        {/* <div className="account__or">
          <p>Or Easily Using</p>
        </div>
        <div className="account__social">
          <Link
            className="account__social-btn account__social-btn--facebook"
            to="/pages/one"
          ><FacebookIcon />
          </Link>
          <Link
            className="account__social-btn account__social-btn--google"
            to="/pages/one"
          ><GooglePlusIcon />
          </Link>
        </div> */}
      </div>
    </div>
  </div>
);

LogIn.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
