import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
/* import { useTranslation } from 'react-i18next'; */
import Form from './components/ResetPasswordForm';
import Alert from '../../shared/components/Alert';
import { API } from '../../services/api';
import { paths } from '../../services/paths';

/* eslint-disable consistent-return */

export const ResetPassword: React.FC = () => {
  /* const { t } = useTranslation('common'); */

  const [alertSettings, setAllertSettings] = useState({
    alertIsVisible: false,
    alertMessage: '',
    alertColor: '',
  });

  const { alertIsVisible, alertMessage, alertColor } = alertSettings;

  const path: string = paths.resetPassword;

  const handleClose = () => {
    setAllertSettings({
      alertIsVisible: false,
      alertMessage: '',
      alertColor: '',
    });
  };

  const handleResetPassword = async ({ email } : {email: string}) => {
    const config = {
      method: 'POST',
      body: JSON.stringify({ email }),
    };
    try {
      const response = await API.post(path, config);
      const result = await response.json();

      if (response.status === 200) {
        setAllertSettings({
          alertIsVisible: true,
          alertMessage: result.message,
          alertColor: 'success',
        });
      } else {
        setAllertSettings({
          alertIsVisible: true,
          alertMessage: result.message,
          alertColor: 'danger',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title text-center">Reset password</h3>
              <p className="text-center">
                Enter please your email address and we will send you a link to restore access to your account.
              </p>
            </Col>
            {alertIsVisible && (
              <Row>
                <Col md={12}>
                  <Alert color={alertColor} className="alert--bordered" icon handleClose={handleClose}>
                    <p><span className="bold-text">{alertMessage}</span></p>
                  </Alert>
                </Col>
              </Row>
            )}
          </Row>
          <Row>
            <Form isHorizontal isAboveError handleResetPassword={handleResetPassword} />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;
