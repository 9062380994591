import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserDataBlock from './components/UserDataBlock';

export const UserAccountPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Account</h3>
      </Col>
    </Row>
    <Row>
      <UserDataBlock />
    </Row>
  </Container>
);

export default UserAccountPage;
