import React from 'react';

export const createAuthorAvatar = (name: string) => {
  const colors = ['#0000FF', '#8A2BE2', '#A52A2A', '#DC143C'];

  const randomColor = colors[Math.floor(Math.random() * Math.floor(3))];

  const firstNameAndLastName = name.split('');

  /* const initials = `${firstNameAndLastName[0].substr(0, 1)} ${firstNameAndLastName[1].substr(0, 1)}`; */

  const initial = `${firstNameAndLastName[0].substr(0, 1)}`;

  console.log(typeof initial);

  return (
    <div className="topbar__avatar-img" style={{ backgroundColor: randomColor, color: '#ffffff' }}>
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        color: '#ffffff',
        transform: 'translate(-50%, -50%)',
      }}
      >
        {initial.toUpperCase()}
      </div>
    </div>
  );
};

export default createAuthorAvatar;
