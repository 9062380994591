import resources from './resources';

const selectedLanguage = JSON.parse(window.localStorage.getItem('selectedLanguage'));

export const config = {
  interpolation: { escapeValue: false }, // React already does escaping
  lng: selectedLanguage || 'en',
  resources,
};

export { resources };
