export const paths = {
  loginByEmail: '/auth/login-by-email',
  logout: '/auth/logout',
  person: '/person',
  businessList: '/business/list',
  resetPassword: '/auth/reset-password',
  createAccount: '/auth/register',
};

export default paths;
