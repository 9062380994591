import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../AuthContext';
import { API } from '../../services/api';
import { paths } from '../../services/paths';

export const LogOut: React.FC = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { token } : { token: string} = currentUser;
  const path: string = paths.logout;

  const config = {
    headers: { Authorization: `Bearer ${token}` },
    method: 'POST',
  };

  const handleLogOut = async (event: React.MouseEvent) => {
    event.preventDefault();
    try {
      const response = await API.post(path, config);
      const result = await response.json();

      if (response.status === 200) {
        setCurrentUser({});
        window.localStorage.setItem('currentUser', '{}');
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const icon = 'exit';

  return (
    <>
      <Link className="topbar__link" to="/" onClick={handleLogOut}>
        <span className={`topbar__link-icon lnr lnr-${icon}`} />
        <p className="topbar__link-title">LogOut</p>
      </Link>;
    </>
  );
};

export default LogOut;
