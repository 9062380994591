import { createContext } from 'react';

const business: any = {
  currentBusiness: '',
  setCurrentBusiness: () => {},
};

export const BusinessContext = createContext(business);

export default BusinessContext;
