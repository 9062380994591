import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../AuthContext';
import { LogIn } from '../../pages/LogInPage';
import { API } from '../../services/api';
import { paths } from '../../services/paths';

const path: string = paths.loginByEmail;

export const LogInAuth: React.FC = () => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password } = event.currentTarget;

    const config = {
      method: 'POST',
      body: JSON.stringify({ email: email.value, password: password.value }),
    };
    try {
      const response = await API.post(path, config);
      const result = await response.json();

      if (response.status === 200) {
        setCurrentUser(result);
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return currentUser.token ? <Redirect to={{ pathname: '/pages/account' }} />
    : <LogIn handleLogin={handleLogin} />;
};

export default LogIn;
