import { useMemo } from 'react';

const CreateTableData = (data) => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'number',
        disableGlobalFilter: true,
      },
      {
        Header: 'Business',
        accessor: 'business',
      },
      {
        Header: 'Action',
        accessor: 'select',
        disableGlobalFilter: true,
      },
    ],
  );
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
