import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import { LogInAuth } from '../../authentification/LogIn';
import { FetchUserData } from '../FetchUserData';
import { UserAccountPage } from '../../pages/UserAccountPage';
import { ResetPassword } from '../../authentification/ResetPassword';
import { PrivateRoute } from '../../components/PrivateRoute';
import { LogOut } from '../../authentification/LogOut';
import { CreateAccount } from '../../authentification/CreateAccount';

const Pages = () => (
  <Switch>
    <Route path="/pages/account" component={UserAccountPage} />
    <Route path="/pages/my-business" component={FetchUserData} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/pages" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogInAuth} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/create-account" component={CreateAccount} />
        <PrivateRoute exact path="/logout" component={LogOut} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
