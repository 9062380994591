import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ResizableTable from '../../../components/ResizableTable';
import { BusinessContext } from '../../../BusinessContext';

type BusinessListProps = {
  title: string,
  id: number | string,
};

export const BusinessList = ({ userBusinessData } : {userBusinessData: BusinessListProps}) => {
  const { t } = useTranslation('common');

  const { currentBusiness, setCurrentBusiness } = useContext(BusinessContext);

  useEffect(() => {
    if (currentBusiness) {
      const button : HTMLElement | null = document.getElementById(currentBusiness.id);
      if (button !== null) {
        button.className = 'btn btn-warning btn-sm';
        button.innerText = 'Active';
      }
    }
  }, []);

  const businesData: any = [];

  if (userBusinessData instanceof Array) {
    userBusinessData.map((business: BusinessListProps, index: number) => {
      const handleSelect = (event: any) => {
        setCurrentBusiness(business);

        const buttons = Array.from(document.getElementsByClassName('btn-sm'));

        buttons.forEach((button) => {
          button.innerHTML = 'Select';
          button.className = 'btn btn-success btn-sm';
        });
        event.currentTarget.className = 'btn btn-warning btn-sm';
        event.currentTarget.innerHTML = 'Active';
      };

      const buttonInitialAtributes: any = {
        id: business.id,
        color: 'success',
        size: 'sm',
      };

      const selectBusiness = () => (
        <ButtonToolbar>
          <Button
            {...buttonInitialAtributes}
            onClick={handleSelect}
          >
            Select
          </Button>
        </ButtonToolbar>
      );

      return businesData.push({ number: index + 1, business: business.title, select: selectBusiness() });
    });
  }

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('myBusinessPage.headers.boldText')}</h5>
            <h5 className="subhead">{t('myBusinessPage.headers.subhead')}</h5>
          </div>
          <ResizableTable data={businesData} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default BusinessList;
