import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';

import LanguageContext from '../../../LanguageContext';
import flagIsrael from '../../../assets/images/flagIsrael.png';
import flagGB from '../../../assets/images/flagGB.png';
import flagRussia from '../../../assets/images/flagRussia.png';

const GbLng = () => (
  <span className="topbar__language-btn-title">
    <img src={flagGB} alt="gb" />
    <span>EN</span>
  </span>
);

const RuLng = () => (
  <span className="topbar__language-btn-title">
    <img src={flagRussia} alt="ru" />
    <span>RUS</span>
  </span>
);

const HebLng = () => (
  <span className="topbar__language-btn-title">
    <img src={flagIsrael} alt="heb" />
    <span>HEB</span>
  </span>
);

const TopbarLanguage = () => {
  const { selectedLanguage, setLanguage } = useContext(LanguageContext);
  const { i18n } = useTranslation('common');
  const [isCollapsed, setIsCollapsed] = useState(false);

  let languageButtonContent;

  if (selectedLanguage === 'heb') {
    languageButtonContent = <HebLng />;
  } if (selectedLanguage === 'ru') {
    languageButtonContent = <RuLng />;
  } else languageButtonContent = <GbLng />;

  const [mainButtonContent, setMainButtonContent] = useState(languageButtonContent);

  const toggleLanguage = () => {
    setIsCollapsed(!isCollapsed);
  };

  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng)
      .then(() => {
        switch (lng) {
          case ('en'):
            setMainButtonContent(<GbLng />);
            break;
          case 'ru':
            setMainButtonContent(<RuLng />);
            break;
          case 'heb':
            setMainButtonContent(<HebLng />);
            break;
          default:
            break;
        }
      });
  };

  return (
    <div className="topbar__collapse topbar__collapse--language">
      <button className="topbar__btn" type="button" onClick={toggleLanguage}>
        {mainButtonContent}
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="language button"
          onClick={toggleLanguage}
        />
      )}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content topbar__collapse-content--language"
      >
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('en')}
        >
          <GbLng />
        </button>
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('ru')}
        >
          <RuLng />
        </button>
        <button
          className="topbar__language-btn"
          type="button"
          onClick={() => changeLanguage('heb')}
        >
          <HebLng />
        </button>
      </Collapse>
    </div>
  );
};

export default TopbarLanguage;
